@text-gray: #AAAAAA;
@white: #FFFFFF;
@black: #000000;
@off-black: #1D1D1D;
@dark-gray: #222222;
@light-gray: #EBEBEB;
@line-gray: #7A7A7A;
@market-main: #E3173E;
@market-hover: #215583;

.juicer-feed a:hover {
  color: #b2b2b2;
}
.juicer-feed .j-meta a:hover {
  color: #000000;
}
.j-twitter-poster:hover {
  color: #b2b2b2;
}

:root {
  --market-main: @market-main;
  --market-hover: #E3173E;
  --mobile-nav-level2-bg: #CDCDCD;
  --mobile-nav-level2-fg: white;
  --mobile-nav-level3-bg: white;
  --mobile-nav-level3-fg: black;
}

.theme--hci-automobiles {
  .nav-logo img {
    width: 80px;
  }
}

.theme--hci-home, .theme--hci-corporate {
  .nav-logo img {
    height: 18px !important;
    @media(min-width: @screen-sm-min) {
      height: 28px !important;
    }
  }
}

.nav-logo img {
  @media(max-width: @screen-xs-max) {
    height: 35px;
  }
}

ul.navbar-actions {
  li.choose-lang {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 15px;
    a {
      display: inline;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

#side-navbar {
  ul.submenu {
    li:first-child {
      display: none;
    }
  }
}

@import '_base.less';